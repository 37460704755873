
import React from "react";
import { useState, useEffect, useRef } from "react";
import { Modal, ModalHeader, ModalBody, Card, CardBody, ButtonToolbar, Table, ButtonGroup, Row, Col, Badge } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from 'react-hook-form';
import DoAutoCompleteField from "../../../Form/Fields/DoAutoCompleteField";
import fetchMethodRequest from '../../../../config/service';
import DeleteRowModal from "../DeleteRowModal";
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';
import AddCartDistributors from "./AddCartDistributors";

import Loader from "../../../App/Loader";
import { Form } from "react-router-dom";
import DoSelectField from "../../../Form/Fields/DoSelectField";
import DoInputField from "../../../Form/Fields/DoInputField";
import { Dialog } from 'primereact/dialog';

import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";


const WishlistModal = (props) => {

  let [productDetails, setProductDetails] = useState([])
  const [selectedRows, setSelectedRows] = useState([])
  const [storageUnitId, setStorageUnitId] = useState(props.storageUnitId)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [isDistributors, setIsDistributors] = useState(false)
  const [selectActions, setSelectActions] = useState();
  let [colored, setColored] = useState(false);
  let [header, setHeader] = useState(true);
  let [schema, setSchema] = useState({});
  const [editedValue, setEditedValue] = useState(""); // State to hold the edited value
  const [updatedProductDetails, setUpdatedProductDetails] = useState([]);
  let [isLoading, setIsLoading] = useState(false);
  const [selectedCategoryType, setSelectedCategoryType] = useState('');
  const [productsList, setProductList] = useState([])



  const buttonRef = useRef(null);

  const [isEmail, setIsEmail] = useState({
    name: "distributorEmail",
    type: "relateAutoComplete",
    placeholder: "Distributor Email",
    label: " Enter Email",
    width: "150px",
    addFormOrder: 8,
    editFormOrder: 8,
    derivedValue: "distributorEmail=undefined",
    capitalizeTableText: false,
    sortable: false,
    isClickable: false,
    filter: false,
    actions: [],
    actionsNumber: [],
    id: "distributorEmail",
    displayinaddForm: "true",
    displayineditForm: "true",
    displayinlist: "true",
    // isFieldRequired: "true",
    // required: true,
    displayOptionsInActions: false,
    globalSearchField: "false",
    controllerId: 1017,
    customCriteria: [{ key: 'companyId', value: props.adminCompanyId, type: 'eq' }],
    searchField: "distributorEmail",
    fieldType: "relateAutoComplete",
    populteFields: [],
    displayFields: [],
    controllerName: "distributorEmail",
    searchApi: "distributors",
    isMultiple: false,
    show: true,
    showOrHideFields: [],
    fieldName: "distributorEmail",
    mobile: true,
    displayInSettings: true,
    isAddFormHidden: false,
    isEditFormHidden: false,
  },
  )

  const [categoryTypeDropdown, setCategoryTypeDropdown] = useState({
    name: "categoryType",
    type: "dropDown",
    // placeholder: "Status",
    label: "Category Type",
    width: "150px",
    addFormOrder: 10,
    editFormOrder: 10,
    derivedValue: "categoryType=categoryType=categoryType=undefined",
    capitalizeTableText: false,
    sortable: false,
    isClickable: false,
    filter: false,
    actions: [],
    actionsNumber: [],
    id: "categoryType",
    displayinaddForm: "true",
    displayineditForm: "true",
    displayinlist: "true",
    isFieldRequired: "true",
    required: false,
    displayOptionsInActions: false,
    globalSearchField: "true",
    controllerId: null,
    options: [
      { label: "Medicine", value: "Medicine" },
      { label: "Non-Medicine", value: "Non-Medicine" }
    ],
    fieldType: "dropDown",
    dependent: [],
    show: true,
    showOrHideFields: [],
    mobile: true,
    displayInSettings: true,
    isAddFormHidden: false,
    isEditFormHidden: false,
  })

  // Function to handle input value change


  const getDistributorFormFields = () => {


    return [
      {
        name: "firstName",
        type: "text",
        placeholder: "First Name",
        label: "First Name",
        width: "130px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "FirstName=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "firstName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        // required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "firstName",
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "lastName",
        type: "text",
        placeholder: "Last Name",
        label: "Last Name",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "lastName=lastName=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "lastName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "status",
        type: "dropDown",
        placeholder: "Status",
        label: "Status",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 10,
        derivedValue: "status=status=status=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "status",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        options: [
          { label: "Active", value: "Active", color: "success" },
          { label: "Pending", value: "Pending", color: "warning" },
          { label: "InActive", value: "InActive", color: "danger" },
          { label: "Dismissed", value: "Dismissed", color: "info" },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "phoneNumber",
        type: "text",
        placeholder: "Phone Number",
        label: "Phone Number",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "phoneNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "phoneNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "distributorEmail",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "distributorEmail=undefined",
        capitalizeTableText: false,
        sortable: true,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "distributorEmail",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },

    ]

  }



  let {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
  });




  useEffect(() => {
    setSchema(yup.object().shape({
      distributorEmail: yup.object().required('Distributor Email  is required')
    }));
    const modal = document.querySelector('div[tabindex="-1"][style*="z-index: 1050;"]');
    if (modal) {
      modal.style.zIndex = '300';
    }
    getCartItems()
    // getCartIncrement()

  }, []);


  const getCartItems = (selectedCategoryType) => {
    let api = 'cartLists/getCartByStorageUnitId'
    // let url = `${api}?storageUnitId=${storageUnitId}&companyId=${props.adminCompanyId}`
    let url = `${api}?storageUnitId=${storageUnitId}&companyId=${props.adminCompanyId}&categoryType=${selectedCategoryType}`
    return fetchMethodRequest('GET', url).then(async (response) => {
      if (response && response.cartList) {
        let products = response.cartList.products ? response.cartList.products : []
        let productsList = response && response.cartList && response.cartList.productsList ? response.cartList.productsList : []
        if (!selectedCategoryType) {
          await setProductList(productsList)
        }
        await setProductDetails(products)
        setValue('products', products)
      }
    }).catch((err) => {
      return err
    })
  }




  // incresing cart count
  //  const getCartIncrement = () =>{
  //   let api = 'cartLists'
  //   let url = `${api}?StorageUnitId=${storageUnitId}`
  //   return fetchMethodRequest('GET', url).then(async (response) => {
  //     if (response && response.cartlists) {
  //       let products = response.cartlists[0].products ? response.cartlists[0].products : []
  //       setCartCount(products.length)
  //     }
  //   }).catch((err) => {
  //     return err
  //   })
  // }




  //getModalView
  let getModalView = () => {
    let { handleSubmit } = props;
    let modalClass = classNames({
      'modal-dialog--colored': colored,
      'modal-dialog--header': header,
    });
  }


  let closeFormModal = async () => {

    // props.reset();
    props.closeFormModal();
    // clearFormFields();
  }

  // seleteConfirmationFun
  let seleteConfirmationFun = async (item) => {
    await setSelectedRows(item);
    setOpenDeleteModal(true);

  }

  //close delete modal
  const closeDeleteModal = async () => {
    setOpenDeleteModal(false);
    // setActions('');
  }

  // close distributors
  const closeDistributors = () => {
    setIsDistributors(false)
  }

  // delete record

  const submitActionsData = async (method, url, body) => {
    return fetchMethodRequest(method, url, body)
      .then(async (response) => {
        setOpenDeleteModal(false);
        setSelectedRows('');
        // setActions('');
        // setSelectedRowsId('');
        if (response && response.respCode) {
          showToasterMessage(response.respMessage, 'success');
          getCartItems(selectedCategoryType);
          props.getDataFromServer(props.filterCriteria, props.categoryType,)
          // getCartIncrement();
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
        }
      }).catch((err) => {
        return err;
      });
  }

  const handleActions = async (apiType) => {
    let ids = selectedRows._id
    let apiUrl = 'cartLists/removeCartListProduct';
    let method, url, body = {}
    // if (confirmType === 'Delete') {
    url = `${apiUrl}`;
    method = 'POST';
    const loginCredentials = localStorage.getItem("loginCredentials");
    const credentialsObject = JSON.parse(loginCredentials);
    let loginRole = credentialsObject.role;
    const companyId = credentialsObject._id;
    body = {
      storageUnitId: storageUnitId,
      productId: selectedRows && selectedRows._id,
      companyId: loginRole === 'Admin' ? companyId : loginRole === 'Super Admin' ? props.adminCompanyId : null,
      loginRole: loginRole

    }
    // }
    submitActionsData(method, url, body)
  }
  // delete selected row
  const deleteSelectedRow = async () => {
    handleActions('multiDelete')
  }

  // send Data To Server

  const sendDataToServer = (formValues) => {
    let userBody = Object.assign({}, formValues);
    let apiUrl = isDistributors ? 'distributors' : 'orders'
    let method = "POST"
    const loginCredentials = localStorage.getItem("loginCredentials");
    const credentialsObject = JSON.parse(loginCredentials);
    let loginRole = credentialsObject.role
    const companyId = credentialsObject._id;
    userBody.loginRole = `${loginRole}`;
    userBody.companyId = loginRole === 'Admin' ? `${companyId}` : loginRole === 'Super Admin' ? `${props.adminCompanyId}` : null;
    let storageUnitId = props.storageUnitId && props.storageUnitId
    let allUsersData = props && props.allUsersData && props.allUsersData

    if (apiUrl === "orders") {
      userBody.distributorEmail = formValues.distributorEmail ? formValues.distributorEmail && formValues.distributorEmail.distributorEmail : null;
      userBody.storageUnitId = storageUnitId
      userBody.productId = formValues.products ? formValues.products[0].productId : null
      userBody.facilityName = allUsersData[0].facilityName
      userBody.productsList = productsList

    }


    return fetchMethodRequest(method, apiUrl, userBody)
      .then(async (response) => {
        if (response && response.respCode) {
          isDistributors ? closeDistributors() : props.closeFormModal();
          await props.getDataFromServer(props.filterCriteria, props.categoryType);
          showToasterMessage(response.respMessage, 'success');
          setIsLoading(false)

          // if (props.displayViewOfForm === 'modal') {
          // props.closeFormModal();
          // } else {
          //   if (formType !== 'add') {
          //     window.location.href = `/${props.routeTo}`;
          //   } else {
          //     props.closeFormModal('save', response.quantityId);
          //   }
          // }
          props.reset();
        } else if (response && response.errorMessage) {
          showToasterMessage(response.errorMessage, 'error');
          setIsLoading(false)

        }

      }).catch((err) => {
        return err;
      });
  }

  const submit = async (values) => {
    if (values) {
      if (props.type === 'MissingItems' && values && values.products) {
        values.products.forEach((product, index) => {
          product.required = productDetails[index].required;
        });
        setIsLoading(true)
        sendDataToServer(values)


      } else {
        setIsLoading(true)
        sendDataToServer(values)

      }
    }
  }


  let getModalHeader = () => {
    let { formType } = props;
    return (
      <ModalHeader className="modal__header">
        {/* <button className="lnr lnr-cross modal__close-btn" type="button" onClick={closeFormModal} /> */}
        <p className="bold-text  modal__title">
          Add To Order List
        </p>
      </ModalHeader>
    )
  }

  // DropDown Field
  let getDropdown = (i, item) => {
    return (
      <div className="col-md-4">
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoSelectField
              input={field}
              markReq={item.required}
              id={field.id}
              name={field.name}
              field={field}
              label={item.label}
              fieldState={fieldState}
              errors={errors}
              options={item.options}
              optionLabel={item.optionLabel ? item.optionLabel : 'label'}
              placeholder={item.placeholder}
            />)}
        />
      </div>
    )
  }
  // Text Field
  let getDefault = (i, item) => {
    return (
      <div className="col-md-4">
        <Controller
          name={item.name ? item.name : null}
          control={control}
          render={({ field, fieldState }) => (
            <DoInputField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              label={item.label}
              field={field}
              type={item.type ? item.type : "text"}
              fieldState={fieldState}
              errors={errors}
              defVal={item.value}
              placeholder={item.placeholder ? item.placeholder : null}
            />)}
        />
      </div>
    )
  }

  const getFields = async (item) => {
    return (
      <>
        <div className="form__form-group mb-3 ml-1" >
          {item.type === "dropDown"
            ? getDropdown('i', item)
            : getDefault('i', item)
          }

        </div>

      </>
    )
  }

  const AddDistributor = (e) => {
    e.preventDefault();

    setIsDistributors(!isDistributors)
    // buttonRef.current.blur();
  }

  // const customLabel = (props) => {
  //   return <div className="">
  //     {props.label}
  //     <Button
  //       color="primary"
  //       size="sm"
  //       // className="p-1 ml-4 mb-0 rounded-circle"
  //       onClick={(e) => AddDistributor(e)}
  //     >
  //       <FontAwesomeIcon
  //         icon={faPlus}
  //         className='plus-icon' size='lg'
  //         data-toggle="tooltip" title={"Add"}
  //       />
  //     </Button>
  //   </div>
  // }



  const customLabel = (props) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <span style={{ flex: '1', marginRight: '0.5rem' }}>{props.label}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="2.5rem"
          height="2em"
          viewBox="0 0 24 24"
          // className='genderIconAlignment mr-2'
          style={{ color: '#3475aa', width: '30', cursor: 'pointer' }}
          title="Add"
          onClick={(e) => AddDistributor(e)}
        >
          <path
            fill="#2d6eb4"
            d="M12 20c-4.41 0-8-3.59-8-8s3.59-8 8-8s8 3.59 8 8s-3.59 8-8 8m0-18A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2m1 5h-2v4H7v2h4v4h2v-4h4v-2h-4z"
          />
        </svg>
      </div>
    );
  };


  let getViewBody = () => {
    // let item = distributorsFields
    let details = isEmail
    let details1 = categoryTypeDropdown
    return (

      <>
        {/* Buttons */}
        <form onSubmit={handleSubmit(submit)} autoComplete={'off'}>

          {/* Button */}
          <div className='d-flex'>
            <div className='col-12 px-0'>
              <span className='float-end'>
                <ButtonToolbar>
                  <Button color='primary' type="button" className='btn custom-outline' outline onClick={() => closeFormModal()}>
                    {'Cancel'}
                  </Button>
                  <Button color='primary' className='btn custom-outline' outlined type="submit">
                    {"Add To Orders List"}
                  </Button>
                </ButtonToolbar>
              </span>
            </div>
          </div>

          {/* Autocomplete */}
          {/* //details1 */}
          <div className=" d-flex col-md-10">
            <Controller
              name={details.name}
              control={control}
              render={({ field, fieldState }) => (
                <DoAutoCompleteField
                  markReq={details.required}
                  input={field}
                  id={field.id}
                  name={field.name}
                  field={field}
                  filterField={details.filterField}
                  filterValue={details.filterValue}
                  filterType={details.isNotEq}
                  multiple={details.isMultiple}
                  fieldState={fieldState}
                  errors={errors}
                  displayCol={"col-md-6"}
                  screen={props.type}
                  searchApi={details.searchApi}
                  searchField={details.searchField}
                  allow={props.allowDuplicates}
                  filterFieldType={details.filterFieldType ? details.filterFieldType : null}
                  placeholder={'POC Email'}
                  // label={details.label}
                  label={'POC Email'}
                  customLabel={customLabel}
                  item={details}
                  getValues={getValues}
                />)}
            />&nbsp;&nbsp;


            <Controller
              name={details1.name}
              control={control}
              defaultValue="Medicine"
              render={({ field, fieldState }) => (
                <DoSelectField
                  markReq={details1.required}
                  input={field}
                  id={field.id}
                  name={field.name}
                  field={field}
                  filterField={details1.filterField}
                  filterValue={details1.filterValue}
                  filterType={details1.isNotEq}
                  multiple={details1.isMultiple}
                  fieldState={fieldState}
                  errors={errors}
                  displayCol={"col-md-9"}
                  screen={props.type}
                  // searchApi={details.searchApi}
                  options={details1.options}
                  // searchField={details.searchField}
                  allow={props.allowDuplicates}
                  // filterFieldType={details.filterFieldType ? details.filterFieldType : null}
                  placeholder={details1.placeholder}
                  label={details1.label}
                  item={details1}
                  getValues={getValues}
                  onChange={(e) => {
                    const selectedCategoryType = e;
                    setSelectedCategoryType(selectedCategoryType);
                    getCartItems(selectedCategoryType);
                    field.onChange(e);
                  }}


                />)}

            />&nbsp;&nbsp;

            {/* showDistributorData  */}

            {/* <div className="" style={{ marginLeft: "1rem", borderRadius: '20px' }}> */}
            {/* <Button
              color="primary"
              size="sm"
              className="p-1 ml-4 mt-4 mb-0 rounded-circle"
              onClick={(e) => AddDistributor(e)}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className='plus-icon' size='lg'
                data-toggle="tooltip" title={"Add"}
              />
            </Button> */}
            {/* </div> */}
            {/* <div>
              <Button rounded className="drugsfield_add_icon"><FontAwesomeIcon
                icon={faPlus}
                size='lg'
                data-toggle="tool-tip"
                title={"Add"}
                onClick={openFormModal}
              /></Button>
            </div> */}
          </div>
        </form>

        {/* Distributors Email Fields */}

        {isDistributors ?
          <AddCartDistributors
            sendDataToServer={sendDataToServer}
            closeFormModal={closeDistributors}
            formFields={getDistributorFormFields}
          />
          : null}

        {/* Data Table */}

        <div style={{ width: "100%", marginTop: "20px", maxHeight: "300px", overflow: "auto" }}>
          <div className="col-12 modalTbody pl-0 pr-0" >
            <Loader loader={isLoading} />
            <Table>
              <thead style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "white" }}>
                <tr>
                  <th>S.No</th>
                  <th>Product Name</th>
                  <th>Description</th>
                  <th>Dosage</th>
                  <th>Need To Order</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody style={{ maxHeight: 80 }}>
                {productDetails && productDetails.length > 0 ?
                  productDetails.map((items, i) => {
                    return <tr>
                      <td > {items.productName ? i + 1 : ""}</td>
                      <td>{items.productName}</td>
                      <td>{items.description ? items.description : ""}</td>
                      <td>{items.volume ? items.volume : '-'}</td>
                      {/* <td>{items.required}</td> */}
                      <td>
                        {
                          props.type === 'MissingItems' ? (
                            <input
                              style={{ width: '7rem' }}
                              type='number'
                              value={items.required}
                              onChange={(e) => {
                                const newValue = parseInt(e.target.value);
                                // if (newValue && newValue >= 0) {
                                const updatedFields = [...productDetails];
                                updatedFields[i].required = newValue;
                                setProductDetails(updatedFields);
                                const allProcedures = [...productsList];
                                const index = allProcedures.findIndex((obj) => obj._id == updatedFields[i]._id)
                                allProcedures[index].required = newValue;
                                setProductList(allProcedures);
                                // }
                              }}
                            />
                          ) : (
                            items.required
                          )
                        }
                      </td>
                      <td>
                        <FontAwesomeIcon
                          className='genderIconAlignment'
                          color='white'
                          icon='trash-alt'
                          title="Delete"
                          style={{ color: '#bf1725', width: '13', marginLeft: 10, cursor: 'pointer' }}
                          onClick={() =>
                            seleteConfirmationFun(items)
                          }
                        />
                      </td>
                    </tr>
                  }) : ""}
              </tbody>
            </Table>
          </div>
        </div>
      </>
    )
  }

  const getDeleteRowModal = () => {
    return (
      <DeleteRowModal
        openDeleteModal={openDeleteModal}
        closeDeleteModal={closeDeleteModal}
        deleteSelectedRow={deleteSelectedRow}
      // confirmModalText={confirmModalText}
      // selectActions={selectActions}
      />
    )
  }

  let modalClass = classNames({
    'modal-dialog--colored': colored,
    'modal-dialog--header': header,
  });

  return (
    <div>
      <div>
        {/* Modal is for Reference  */}
        {/* <Modal
          isOpen={props.openWishlistModal}
          className={`modal-dialog-centered modal-dialog--primary  ${modalClass}`}
          style={{ maxWidth: "65%" }}
        >
          {getModalHeader()}
          {
            <ModalBody className="modal__body mb-0 pt-1">
              <Card className='pb-0 cardForListMargin'>
                <CardBody className='tableCardBody'>
                  {getViewBody()}
                </CardBody>
              </Card>
            </ModalBody>
          }
        </Modal> */}

        <Dialog
          visible={props.openWishlistModal}
          onHide={closeFormModal}
          draggable={false}
          style={{ width: '80vw' }}
          header={getModalHeader()}
          closeOnEscape={false}
        >
          {
            <ModalBody className="modal__body mb-0 pt-4">
              <Card className='pb-0 cardForListMargin'>
                <CardBody className='tableCardBody'>
                  {getViewBody()}
                </CardBody>
              </Card>
            </ModalBody>
          }
        </Dialog>
      </div>

      {openDeleteModal ? getDeleteRowModal() : null}

    </div>

  )



}
export default WishlistModal;